import React, { useState } from "react";
import { MessageList, Input } from "react-chat-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-chat-elements/dist/main.css";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import "react-chat-elements/dist/main.css";
import { ReactComponent as Animation } from "./spinner.svg";

var chat_historyy = [];

function AnimatedSVG() {
  return <Animation width="50" height="50" />;
}
function Chat() {
  const messageListReferance = React.createRef();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Inicializa como habilitado

  const sendRequestToRemoteServer = (user_message, chat_history) => {
    console.log(
      JSON.stringify({
        user_message: user_message,
        chat_history: JSON.stringify(chat_history || []), // Transforma em uma string JSON
      })
    );

    const body = JSON.stringify({
      user_message: user_message,
      chat_history: JSON.stringify(chat_history || []), // Transforma em uma string JSON
    });

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    };

    fetch("https://iaenap.mizu.com.br:8081/get_response", options)
      .then((response) => response.json())
      .then((data) => {
        console.log(1);
        setIsButtonDisabled(false);
        chat_historyy.push({
          user_message: user_message,
          answer: data.answer,
        });

        console.log(2);
        console.log(chat_historyy);

        setMessages((prevMessages) => {
          // Faz uma cópia das mensagens anteriores
          const newMessages = [...prevMessages];

          // Modifica a última mensagem
          newMessages[newMessages.length - 1] = {
            ...newMessages[newMessages.length - 1],
            text: (
              <p
                dangerouslySetInnerHTML={{
                  __html: data.answer.replace(/\n/g, "<br>"),
                }}
              />
            ),
          };

          return newMessages;
        });

        console.log(data); // Aqui você pode fazer o que quiser com a resposta do servidor remoto
      })
      .catch((error) => {
        setIsButtonDisabled(false); // Garante que o botão seja habilitado mesmo em caso de erro
        console.error("Erro ao enviar requisição:", error);
      });
  };
  const av = "./logo.jpg";
  const nomee = "Axcel Kuhn";
  const initials = nomee
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  const [messages, setMessages] = useState([
    {
      type: "text",
      text: (
        <p
          dangerouslySetInnerHTML={{
            __html:
              "Olá! Sou a assistente virtual da Mizu, pronta para ajudar. Que pergunta você tem para mim hoje?",
          }}
        />
      ),
      position: "left",
      avatar: av,
      alt: initials,
    },
  ]);

  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true); // Desabilita o botão enquanto a requisição está sendo feita
      setMessages([
        ...messages,
        {
          type: "text",
          text: message,
          position: "right",
          alt: initials,
        },
      ]);

      const loadingMessage = {
        type: "text",
        text: <AnimatedSVG />,
        position: "left",
        avatar: av,
        alt: initials,
      };
      setMessages((prevMessages) => [...prevMessages, loadingMessage]);

      sendRequestToRemoteServer(message, chat_historyy);
      setMessage("");
    }
  };

  return (
    <div className="chat-container">
      <MessageList
        referance={messageListReferance}
        className="message-list"
        lockable={true}
        toBottomHeight={"100%"}
        dataSource={messages}
        customClass={{
          right: {
            background: "#025c4c",
            color: "#fff",
          },
        }}
      />
      <div className="input-container">
        <Input
          className="custom-input"
          placeholder="Digite uma mensagem"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rightButtons={
            <FontAwesomeIcon
              icon={faPaperPlane}
              style={{
                backgroundColor: "#00a884", // Define a cor do círculo
                color: "#fff", // Define a cor do ícone
                width: "15px", // Ajusta o tamanho do ícone (opcional)
                height: "15px", // Ajusta o tamanho do ícone (opcional)
                borderRadius: "35%", // Torna o elemento um círculo
                padding: "10px", // Ajusta o espaço ao redor do ícone (opcional)
                cursor: "pointer",
              }}
              disabled={isButtonDisabled}
              onClick={handleSend}
            />
          }
        />
      </div>
    </div>
  );
}

export default Chat;
