import logo from './logo.svg';
import './App.css';
import Chat from "./ChatComponent";
import "@fortawesome/fontawesome-svg-core/styles.css";

function App() {
  return (
    <div className="App">
      <Chat /> {/* Adicionando o componente Chat aqui */}
    </div>
  );
}

export default App;
